.app > header {
  display: flex;
  justify-content: center;
  width: 99vw;
  border-bottom: 1px solid rgb(239, 241, 236);
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: rgba(255, 255, 255, 1);
}

@supports (-webkit-backdrop-filter: blur()) or (backdrop-filter: blur()) {
  .app > header {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.618);
  }
}

.app > header > nav {
  width: 100%;
  margin: 0 auto;
  max-width: 1180px;
  padding: 16px 20px;
  padding-right: 12px;
  padding-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.app > header > nav > .actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.app > header > nav > .actions .navicon {
  margin: 8px;
  margin-right: 24px;
}

.app > header > nav a {
  color: black;
  /* height: 21px; */
}
