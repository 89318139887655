footer {
  display: flex;
  justify-content: center;
  font-size: 0.618rem;
  padding: 12px;
  margin-top: 48px;
  border-width: 0;
  border-top: 1px solid rgb(239, 241, 236);
}

footer > .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  max-width: 1080px;
}

footer .social {
  display: flex;
  flex-direction: row;
  align-items: center;
}

footer .social img {
  height: 18px;
  display: inline-block;
  margin-bottom: -1.5px;
}

footer .social a {
  margin-left: 20px;
}

footer a {
  color: black;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}
