.logo-container {
  display: flex;
  align-items: center;
}

.logo-container img {
  width: 29px;
  height: 29px;
  margin-right: 8px;
  margin-bottom: -2px;
}
