.app {
  display: grid;
  grid-template-rows: auto 1fr auto;
  justify-content: center;
}

.app > main {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.app > main > .app-overview * {
  transition: all 0.3s;
}

.app > main > .app-overview {
  display: grid;
  grid-template-columns: 1.25fr 1fr;
  max-width: 1180px;
  width: 100%;
  padding: 0 20px;
  padding-top: 20vh;
  box-sizing: border-box;
  margin-bottom: 64px;
  align-items: center;
  row-gap: 72px;
}

.app > main > .section {
  padding: 96px 20px;
  text-align: center;
  align-items: center;
  font-weight: 500;
  margin-bottom: 72px;
  flex-direction: row;
  display: grid;
  column-gap: 42px;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
}

.app > main > .section > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app > main > .section h1 {
  font-size: 48px;
  text-align: start;
  margin-top: 0;
}

.app > main > .section > div.text {
  justify-content: flex-start;
  align-items: flex-start;
}

.app > main > .section > div.text p {
  text-align: start;
  max-width: 500px;
  margin: 0;
  margin-bottom: 12px;
}

.app > main > .section > div.text.reverse {
  justify-content: flex-end;
  align-items: flex-end;
}

.app > main > .section > .items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
  max-width: 500px;
  overflow: hidden;
}

.app > main > .section > .items.reverse {
  justify-content: flex-end;
  align-items: flex-end;
}

.app > main > .section > .items img {
  width: 42px;
  height: 42px;
}

.app > main > .communication.section img {
  width: 64px;
}

@media only screen and (max-width: 600px) {
  .app > main > .app-overview {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
    padding-top: calc(100vh - (577px + 72px));
  }

  .app > main > .app-overview > .intro {
    padding-right: 0;
    padding-left: 4px !important;
  }

  .app > main > .app-show img.app-main {
    margin-left: -7%;
  }

  .app > main > .section {
    flex-direction: column !important;
    display: flex;
    padding: 48px 20px;
  }

  .app > main > .section div {
    justify-content: flex-start;
  }

  .app > main > .section div h1 {
    text-align: center;
    align-self: center;
    font-size: 32px;
  }

  .app > main > .section div.text {
    align-items: flex-start !important;
  }

  .app > main > .section > .items {
    justify-content: center !important;
    align-items: center;
  }

  footer .company-address,
  footer .brand-kit {
    display: none;
  }
}

.app a {
  transition: all 0.3s;
}

.app a:hover {
  color: dodgerblue !important;
}

.app a.manually-download {
  text-decoration: none;
  color: black;
  font-size: 12px;
  margin-top: 8px;
}
