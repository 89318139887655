.app > main > .app-overview .app-show {
  position: relative;
  box-sizing: border-box;
  padding-top: 0%;
  padding-left: 15%;
}

.app > main > .app-overview .app-show img.mobile-main {
  width: 85%;
  align-self: center;
}

