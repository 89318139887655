.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  color: #333;
  font-family: Arial, sans-serif;
}

.title {
  font-size: 2.2em;
  font-weight: bold;
  margin-bottom: 10px;
}

.date {
  font-size: 0.9em;
  color: #777;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 20px;
}

.section-title {
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 10px;
}

.paragraph {
  font-size: 1em;
  margin-bottom: 10px;
  line-height: 1.5;
}

.list {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 10px;
}

.list-item {
  margin-bottom: 5px;
  font-size: 1em;
}
