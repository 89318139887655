.app > main > .app-overview > .intro {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: -18%;
  box-sizing: border-box;
  padding-left: 9%;
}

.app > main > .app-overview > .intro h3 {
  color: #333;
  font-size: 2rem;
  line-height: 3rem;
  font-family: 'Avenir Next Bold', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-bottom: 0;
}

.app > main > .app-overview > .intro ul {
  padding: 0;
  font-size: 17px;
  line-height: 21px;
  list-style: none;
}

.app > main > .app-overview > .intro ul li {
  padding: 10px 0;
  text-decoration: none;
  color: #2e4b6b;
  display: grid;
  grid-template-columns: 19px 1fr;
  column-gap: 12px;
  align-items: flex-start;
}

.app > main > .app-overview > .intro ul li svg {
  margin-right: 12px;
  padding-top: 0px;
}

.app > main > .app-overview > .intro img {
  height: 40px;
}

.app > main > .app-overview * {
  transition: all 0.3s;
}

.app > main > .app-overview > .intro > div.downloads .title {
  font-size: 15px;
  margin-top: 24px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.app > main > .app-overview > .intro > div.downloads .title span {
  display: inline-block;
  margin-left: 5px;
  font-size: 12px;
}

.app > main > .app-overview > .intro .mobile-stores #moreOrLess {
  color: black;
  font-size: 12px;
  cursor: pointer;
}

.app > main > .app-overview > .intro .mobile-stores #moreOrLess:hover {
  color: deepskyblue;
  text-decoration: underline;
}

.app > main > .app-overview > .intro .mobile-stores {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: center !important;
  gap: 4px;
}

.app > main > .app-overview > .intro .mobile-stores img {
  /* height: 60px; */
  margin-right: 16px;
}

.app > main > .app-overview > .intro .desktop {
  display: flex;
  flex-wrap: wrap;
  align-items: center !important;
  column-gap: 20px;
  row-gap: 16px;
  align-content: flex-start;
}

.app > main > .app-overview > .intro .desktop a {
  text-decoration: none;
  color: white;
  transition: all 0.3s;
  align-items: center;
  display: flex;
}

.app > main > .app-overview > .intro .desktop > div.apple {
  display: flex;
  align-items: center;
  column-gap: 12px;
  border-radius: 8px;
  color: white;
  background-color: black;
  padding: 7px 12px;
  padding-bottom: 7px;
}

.app > main > .app-overview > .intro .desktop > div.apple img {
  height: 27px;
}

.app > main > .app-overview > .intro .desktop > div.windows {
  margin-top: 5px;
  display: flex;
  align-items: center;
  column-gap: 12px;
  border-radius: 8px;
  color: rgb(3, 3, 3);
  padding: 8px 12px;
}

.app > main > .app-overview > .intro .desktop .windows > a {
  color: black;
}

.app > main > .app-overview > .intro .desktop > div.linux {
  margin-top: 5px;
  display: flex;
  align-items: center;
  column-gap: 12px;
  border-radius: 8px;
  color: rgb(3, 3, 3);
  padding: 8px 12px;
  padding-bottom: 7px;
}

.app > main > .app-overview > .intro .desktop .linux > a {
  color: black;
}

.app > main > .app-overview > .intro .desktop a:hover {
  color: deepskyblue;
}

